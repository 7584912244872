<template>
  <div v-if="option">
    <figure class="image is-4by3 mb-4">
      <ImgLoader
        class="has-background-grey-light"
        :src="option.Image"
        type="location"
        :typeId="locationId"
      />
    </figure>

    <p class="title">{{ name }}</p>

    <p v-html="description"></p>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import ImgLoader from '../ImgLoader.vue'

export default {
  components: { ImgLoader },

  props: {
    locationId: {
      type: Number,
      default: 0,
    },

    option: {
      type: Object,
      default: null,
    },

    language: {
      type: String,
      default: ''
    }
  },

  data() {
    return {}
  },

  computed: {
    name() {
      let name = ''

      if (this.option && this.option.Texts && this.option.Texts.length > 0) {
        let text = this.option.Texts.find(
          (t) => t.Language === this.language
        )

        if (text) {
          name = text.Name
        } else {
          text = this.option.Texts.find((t) => t.Language === 'en')

          if (text) {
            name = text.Name
          }
        }
      }

      return name
    },

    description() {
      let description = ''

      if (this.option && this.option.Texts && this.option.Texts.length > 0) {
        let text = this.option.Texts.find(
          (t) => t.Language === this.language
        )

        if (text) {
          description = text.Description
        } else {
          text = this.option.Texts.Descriptions.find((d) => d.Language === 'en')

          if (text) {
            description = text.Description
          }
        }
      }

      return description
    },

    features() {
      let features = []

      if (
        this.option &&
        this.option.Features &&
        this.option.Features.length > 0
      ) {
        features = this.option.Features.filter(
          (f) => f.Language === this.language
        )

        if (features.length === 0) {
          features = this.option.Features.filter((f) => f.Language === 'en')
        }
      }

      return features
    },
  },

  created() { },

  methods: {},
}
</script>
