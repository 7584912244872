<template>
  <ui-component-modal
    :modalTitle="$t('Components.Proposals.ModalChangeRequest.Maint_Title', localeOverride.locale)"
    :buttonLabelConfirm="$t('Components.Proposals.ModalChangeRequest.Button_Save', localeOverride.locale)"
    :disableSaveButton="disableSaveButton"
    :isSaving="isSaving"
    :isSavingSuccess="isSavingSuccess"
    :isSavingError="isSavingError"
    :hideFooterButtons="isSavingError || isSavingSuccess"
    :onClickCancel="onClickCancel"
    :onClickSave="requestChange"
    :showModal="showModal"
    @closeModal="onClickCancel"
  >
    <template v-slot:content>
      <message
        class="is-info"
        v-if="!notTheApplicant"
      >
        {{ $t('Components.Proposals.ModalChangeRequest.Message_Info', localeOverride.locale) }}
      </message>
      <message
        class="is-warning"
        v-if="notTheApplicant"
      >
        <div v-html="$t('Components.Proposals.ModalChangeRequest.Message_NotTheApplicant', localeOverride.locale, { name: profile.FirstName})"></div>
      </message>
      <textarea
        class="textarea has-margin-bottom"
        v-model="comment"
      />
    </template>
  </ui-component-modal>
</template>

<script>
import { mapState } from 'vuex'
import proposalProvider from '@/providers/proposal'
import Message from '../UI/Message.vue'
import { EventBus } from '../../eventBus/event-bus'

export default {
  components: { Message },

  inject: {
    localeOverride: {
      type: Object,
      default: function() { return { locale: this.$i18n.locale } }
    },
  },

  props: {
    showModal: {
      type: Boolean,
      default: false,
    },

    onClickCancel: {
      type: Function,
      required: true,
    },

    proposal: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      isSaving: false,
      isSavingSuccess: false,
      isSavingError: false,
      comment: '',
      notTheApplicant: false
    }
  },

  computed: {
    ...mapState(['profile']),

    disableSaveButton() {
      return this.comment.trim().length < 10 || this.notTheApplicant
    }
  },

  created() { },

  methods: {
    requestChange() {
      let self = this

      if (!self.isSaving) {
        self.isSaving = true

        proposalProvider.methods
          .proposalChangeRequest(self.proposal.Key, self.comment)
          .then((response) => {
            if (response.status === 200) {
              self.isSavingSuccess = true

              this.$emit('proposalUpdated', response.data)

              // Send message to display toast on screen
              EventBus.$emit('showToast', {
                type: 'info',
                message: this.$t('Components.Proposals.ModalChangeRequest.Message_Saved', this.localeOverride.locale),
              })

              setTimeout(() => {
                this.onClickCancel()
              }, 1500)
            }
          })
          .catch((e) => {
            if (e.response.status === 403) {
              this.notTheApplicant = true
            } else {
              this.isSavingError = true
            }
          })
          .finally(() => {
            self.isSaving = false
          })
      }
    },
  },
}
</script>
