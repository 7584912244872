<template>
  <div class="page-proposalDetail">
    <ModalChangeRequestProposal
      v-if="showChangeRequest"
      :showModal="showChangeRequest"
      :onClickCancel="hideModal"
      :proposal="proposal"
      @proposalUpdated="proposalUpdated"
    />

    <ModalCancelProposal
      v-if="showReject"
      :showModal="showReject"
      :onClickCancel="hideModal"
      :proposal="proposal"
      @proposalCancelled="proposalCancelled"
    />

    <nav class="navbar-custom">
      <div class="navbar-item">
        <router-link
          :to="{ name: 'proposals' }"
          class="navbar-link icon-text is-arrowless"
        >
          <span class="icon is-small">
            <i class="fas fa-chevron-left"></i>
          </span>
          <span>Proposals</span>
        </router-link>
      </div>
    </nav>
    <hr />

    <div class="contentWrapper has-background-white">
      <transition
        name="fade"
        mode="out-in"
      >
        <ui-loader v-if="isLoading" />
        <div
          ref="proposalDetailTop"
          class="proposalDetail"
          v-if="!isLoading && proposal"
        >
          <!-- Location main image -->
          <div class="has-padding is-hidden-desktop">
            <div
              class="subtitle is-size-3-touch is-size-2-desktop"
              v-t="{
                path: 'Views.Proposals.ProposalDetail.Title_Proposal',
                locale: proposalLanguage,
              }"
            ></div>
            <div
              class="title is-size-3-mobile is-size-2-tablet is-size-1-desktop"
              v-html="proposal.Location.Name"
            ></div>
            <div class="is-size-5-touch is-size-3-desktop">
              {{
                proposal.StartDate
                  | dateFormat(proposalLanguage, "longDateFormat")
              }}, {{ proposal.StartMinutes | minutesToTime }} -
              {{ proposal.EndMinutes | minutesToTime }}
            </div>
            <!-- <hr> -->
            <strong>Proposal is valid until
              {{
                proposal.ExpiresOn
                  | dateFormat(proposalLanguage, "longDateFormat")
              }}</strong>
          </div>
          <ImgLoader
            class="hero"
            :src="location.Image"
            type="location"
            :typeId="location.Id"
            tag="div"
          >
            <div class="hero-body">
              <div class="container has-text-left">
                <div class="box-heroTitle is-hidden-touch">
                  <div class="boxTitle p-4">
                    <div
                      class="subtitle is-size-3-touch is-size-2-desktop"
                      v-t="{
                        path: 'Views.Proposals.ProposalDetail.Title_Proposal',
                        locale: proposalLanguage,
                      }"
                    ></div>
                    <div
                      class="title is-size-3-mobile is-size-2-tablet"
                      :class="
                        proposal.Location.Name.length > 30
                          ? 'is-size-2-desktop'
                          : 'is-size-1-desktop'
                      "
                      v-html="proposal.Location.Name"
                    ></div>
                  </div>
                  <div class="boxDetail pl-4 pr-4 pb-4">
                    <div class="mb-2 is-size-5-touch is-size-3-desktop">
                      {{
                        proposal.StartDate
                          | dateFormat(proposalLanguage, "longDateFormat")
                      }}
                    </div>
                    <div class="mb-5 is-size-5-touch is-size-3-desktop">
                      {{ proposal.StartMinutes | minutesToTime }} -
                      {{ proposal.EndMinutes | minutesToTime }}
                    </div>
                    <!-- <hr> -->
                    <div class="buttons">
                      <a
                        v-if="!isInRequestState"
                        @click="pageScrollTo('summary')"
                        class="button is-info is-medium is-fullwidth"
                        v-t="{
                          path: 'Views.Proposals.ProposalDetail.Button_ViewSummary',
                          locale: proposalLanguage,
                        }"
                      ></a>
                      <!-- <a
                        v-if="!isInRequestState"
                        @click="pageScrollTo('approveActions')"
                        class="button is-primary is-medium"
                      >Approve</a> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="box-address">
              <div
                class="has-text-weight-bold is-size-4"
                v-t="{
                  path: 'Views.Proposals.ProposalDetail.Title_Address',
                  locale: proposalLanguage,
                }"
              ></div>
              <div>{{ location.Address }}</div>
              <div>{{ location.Zipcode }}, {{ location.City }}</div>
              <div>{{ location.PhoneNumber }}</div>
              <div>{{ location.Email }}</div>
            </div>
          </ImgLoader>

          <!-- LOCATION DESCRIPTION -->
          <div class="section">
            <div class="columns is-multiline">
              <div
                v-if="locationDescription"
                class="column"
              >
                <div class="message-host has-padding has-margin-bottom-x2 is-size-4">
                  <figure class="image is-64x64">
                    <ImgLoader
                      v-if="host && host.Image"
                      class="has-background-grey is-rounded"
                      :src="host.Image"
                      type="location"
                      :typeId="host.LocationId"
                      tag="div"
                    />
                  </figure>
                  <transition
                    name="fade"
                    mode="out-in"
                  >
                    <div
                      :key="`ht_${hostTextDisplayIndex}`"
                      class="text"
                      v-html="hostTexts[hostTextDisplayIndex]"
                    ></div>
                  </transition>
                </div>
                <!-- Location description -->
                <transition
                  name="fade"
                  mode="out-in"
                >
                  <div
                    v-if="!isInRequestState"
                    key="notInRequestState"
                    v-html="locationDescription"
                  ></div>

                  <!-- Communication stream -->
                  <message
                    v-if="isInRequestState"
                    key="inRequestState"
                    class="is-info"
                  >
                    <div class="is-size-4">Pending...</div>
                  </message>
                </transition>
              </div>
              <div class="column-images column is-full-tablet is-one-third-desktop">
                <div class="columns is-multiline is-mobile">
                  <div
                    v-for="(img, index) in locationImages"
                    :key="'i_' + index"
                    class="column is-full-mobile is-half-tablet is-full-desktop"
                    :class="[
                      { 'is-hidden-touch': index === 2 },
                      { 'is-hidden': isInRequestState && index >= 1 },
                    ]"
                  >
                    <figure class="image is-4by3">
                      <ImgLoader
                        class="has-background-grey-light is-rounded"
                        :src="img.Image"
                        type="location"
                        :typeId="img.LocationId"
                      />
                    </figure>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-if="!isInRequestState">
            <!-- SPACES -->
            <div class="section-spaces section pt-0">
              <div class="section-divider mb-5">
                <div
                  class="title is-1 has-text-centered"
                  v-t="{
                    path: 'Views.Proposals.ProposalDetail.Title_Spaces',
                    locale: proposalLanguage,
                  }"
                ></div>
              </div>
              <div class="columns is-multiline">
                <div class="column is-full">
                  <div
                    v-for="(space, index) in proposal.Spaces"
                    :key="'sp' + index"
                    class="columns-space columns is-multiline"
                  >
                    <div class="
                        column-spaceText column
                        is-half-tablet is-one-third-desktop
                      ">
                      <div
                        class="title is-2"
                        v-html="getSpaceName(space)"
                      ></div>

                      <UpsellTemplate
                        :upsellItems="getSpaceUsps(space)"
                        class="mb-5"
                      />

                      <div
                        v-html="getSpaceDescription(space)"
                        class="mb-5"
                      ></div>
                      <div class="columns is-mobile">
                        <div class="column has-text-centered">
                          <div class="box-settings">
                            <SvgSetting
                              aria-hidden="true"
                              :settingId="space.SettingId"
                              size="is-large has-text-blue-dark"
                            />
                            <div class="subtitle">
                              {{ space.SettingId | getSettingName }}
                            </div>
                          </div>
                        </div>
                        <div class="column has-text-centered">
                          <div class="box-people box-icon">
                            <span class="icon is-large has-text-blue-dark">
                              <font-awesome-icon
                                :icon="['fas', 'users']"
                                size="2x"
                              />
                            </span>
                            <div class="subtitle">{{ space.Seats }}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="column-spaceImage column">
                      <figure class="image-space image is-16by9">
                        <ImgLoader
                          class="has-background-grey-light has-ratio is-rounded"
                          :src="space.Image"
                          type="location"
                          :typeId="locationId"
                          tag="div"
                        />
                      </figure>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- OPTIONS -->
            <section
              v-if="proposal.Options"
              class="section-amenities section pt-0"
            >
              <div class="section-divider mb-5">
                <div
                  class="title is-1 has-text-centered"
                  v-t="{
                    path: 'Views.Proposals.ProposalDetail.Title_Amenities',
                    locale: proposalLanguage,
                  }"
                ></div>
              </div>
              <div class="container">
                <div class="columns is-multiline">
                  <div
                    v-for="(option, index) in proposal.Options"
                    :key="index"
                    :class="optionColumns"
                  >
                    <OptionDetailItem
                      class="optionDetailItem"
                      :locationId="location.Id"
                      :language="proposalLanguage"
                      :option="option"
                    />
                  </div>
                </div>
              </div>
            </section>

            <!-- SUMMARY -->
            <section
              ref="summary"
              class="section-summary section"
            >
              <div class="section-divider mb-5">
                <div
                  class="title is-1 has-text-centered"
                  v-t="{ path: 'Views.Proposals.ProposalDetail.Title_Summary', locale: proposalLanguage }"
                ></div>
              </div>
              <div class="container">
                <div
                  class="is-size-3 has-text-weight-bold"
                  v-html="proposal.Location.Name"
                ></div>
                <div class="is-size-5">{{proposal.StartDate | dateFormat(proposalLanguage, 'longDateFormat') }}</div>
                <div class="is-size-5">{{ proposal.StartMinutes | minutesToTime }} - {{ proposal.EndMinutes | minutesToTime }}</div>
                <div
                  class="is-size-5 mb-5"
                  v-t="{ path: 'Views.Proposals.ProposalDetail.Text_ProposalIsValid', locale: proposalLanguage, args: {
                    date: $options.filters.dateFormat(proposal.ExpiresOn, proposalLanguage, 'longDateFormat')
                  }}"
                ></div>

                <ProposalInvoiceView
                  :proposal="proposal"
                  :language="proposalLanguage"
                />
              </div>
            </section>
            <div ref="approveActions">
              <div class="section-divider section-divider-approve"></div>
              <transition
                name="fade"
                mode="out-in"
              >
                <div
                  v-if="isLoggedIn && notTheApplicant"
                  key="userNotTheApplicant"
                  class="has-padding"
                >
                  <div class="columns is-centered">
                    <div class="column is-half-desktop">
                      <Message class="is-warning">
                        <div v-html="
                            $t(
                              'Views.Proposals.ProposalDetail.Message_NotTheApplicant',
                              proposalLanguage,
                              { name: profile.FirstName }
                            )
                          "></div>
                      </Message>
                    </div>
                  </div>
                </div>

                <div
                  v-if="isLoggedIn"
                  key="userIsLoggedIn"
                >
                  <section class="section section-approve pt-0">
                    <div class="buttons is-centered">
                      <a
                        @click="acceptProposal"
                        class="button is-large mt-4 is-primary"
                        :disabled="isAcceptingProposal"
                        :class="[
                          { 'is-disabled': isAcceptingProposal },
                          { 'is-loading': isAcceptingProposal },
                        ]"
                      >
                        <span
                          class="pl-4 pr-4"
                          v-t="{
                            path: 'Views.Proposals.ProposalDetail.Button_Accept',
                            locale: proposalLanguage,
                          }"
                        ></span></a>
                    </div>
                  </section>
                  <section
                    v-if="!isAcceptingProposal"
                    class="pb-6"
                  >
                    <div
                      class="title is-3 has-text-centered"
                      v-t="{
                        path: 'Views.Proposals.ProposalDetail.Label_Or',
                        locale: proposalLanguage,
                      }"
                    ></div>
                    <div class="buttons is-centered">
                      <a
                        @click="setShowChangeRequest"
                        class="button is-ghost mr-3"
                      >
                        <span class="icon has-text-info">
                          <font-awesome-icon :icon="['fas', 'comment-lines']" />
                        </span>
                        <span v-t="{
                            path: 'Views.Proposals.ProposalDetail.Link_RequestChanges',
                            locale: proposalLanguage,
                          }">Request changes</span>
                      </a>
                      <a
                        @click="setShowReject"
                        class="button is-ghost ml-3"
                      >
                        <span class="icon has-text-danger">
                          <font-awesome-icon :icon="['far', 'times']" />
                        </span>
                        <span v-t="{
                            path: 'Views.Proposals.ProposalDetail.Link_RejectProposal',
                            locale: proposalLanguage,
                          }"></span></a>
                    </div>
                  </section>
                </div>

                <div
                  v-if="!isLoggedIn"
                  key="userNotLoggedIn"
                  class="has-padding"
                >
                  <div class="columns is-centered">
                    <div class="column is-half-desktop">
                      <div
                        class="has-text-weight-bold is-size-5"
                        v-text="
                          $t(
                            'Views.Proposals.ProposalDetail.Message_Header_NotLoggedIn',
                            proposalLanguage
                          )
                        "
                      ></div>
                      <div
                        class="mb-5"
                        v-html="
                          $t(
                            'Views.Proposals.ProposalDetail.Message_Text_NotLoggedIn',
                            proposalLanguage
                          )
                        "
                      ></div>
                      <div class="box">
                        <no-auth-login-flow
                          inputSize=""
                          :logginOnly="true"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </transition>
            </div>
          </div>
        </div>
        <message
          v-if="!isLoading && !proposal"
          class="is-info"
        >
          <div v-html="
              $t(
                'Views.Proposals.ProposalDetail.Message_NotExists',
                proposalLanguage
              )
            "></div>
        </message>
      </transition>
    </div>
  </div>
</template>

<script>
import proposalProvider from "../../providers/proposal";
import logProvider from "../../providers/log";
import locationProvider from "../../providers/location";
import OptionDetailItem from "../../components/UI/Components/OptionDetailItem";
import ProposalInvoiceView from "../../components/Proposals/ProposalInvoiceView";
import ImgLoader from "../../components/UI/ImgLoader.vue";
import SvgSetting from "../../components/UI/Settings/SvgSetting.vue";
import UpsellTemplate from "../../components/UI/UpsellTemplate.vue";
import ModalChangeRequestProposal from "@/components/Proposals/ModalChangeRequestProposal";
import ModalCancelProposal from "@/components/Proposals/ModalCancelProposal";
import ProposalComments from "../../components/Proposals/ProposalComments.vue";
import Message from "../../components/UI/Message.vue";
import { EventBus } from "../../eventBus/event-bus";
import { mapGetters, mapState } from "vuex";
import NoAuthLoginFlow from "../../components/Auth/NoAuthLoginFlow.vue";

export default {
  components: {
    OptionDetailItem,
    ProposalInvoiceView,
    ImgLoader,
    SvgSetting,
    UpsellTemplate,
    ModalChangeRequestProposal,
    ModalCancelProposal,
    Message,
    NoAuthLoginFlow,
    // ProposalComments
  },

  data() {
    return {
      isAcceptingProposal: false,
      isLoading: true,
      proposalKey: this.$route.params.proposalKey,
      proposal: null,
      reservation: null,
      showChangeRequest: false,
      showReject: false,
      host: null,
      hostTexts: [],
      hostTextAnimationInterval: 8000,
      hostTextAnimator: null,
      hostTextLoopAnimation: false,
      hostTextDisplayIndex: 0,
      notTheApplicant: false,
    };
  },

  computed: {
    ...mapState(["profile"]),
    ...mapGetters({ isLoggedIn: "getIsLoggedIn" }),
    isInRequestState() {
      return this.proposal.Status === "ProposalChangeRequest";
    },

    optionColumns() {
      if (this.proposal && this.proposal.Options.length === 0) {
        return "";
      }

      return [
        "column",
        {
          "is-half": this.proposal.Options.length <= 2,
          "is-half is-one-third-tablet": this.proposal.Options.length <= 3,
          "is-half is-one-third-tablet is-one-quarter-desktop":
            this.proposal.Options.length > 3,
        },
      ];
    },

    proposalLanguage() {
      return this.proposal && typeof this.proposal.Language !== "undefined"
        ? this.proposal.Language
        : "en";
    },

    // notTheApplicant() {
    //   return this.proposal.Booker !== this.profile.FirstName + ' ' + this.profile.LastName
    // },

    location() {
      if (this.proposal && this.proposal.Location) {
        return this.proposal.Location;
      } else {
        return null;
      }
    },

    hashedEmail() {
      let arr_bookerEmail = this.proposal.Rfp.ProfileEmail.split("@");
      let arr_lastEmailPart = arr_bookerEmail[1].split(".");
      let firstPart = arr_bookerEmail[0].substr(0, 10);
      firstPart += arr_bookerEmail[0].length >= 10 ? "....." : "";
      return `${firstPart}@.....${arr_lastEmailPart[arr_lastEmailPart.length - 1]
        }`;
    },

    /**
     * Space banner image
     */
    spaceBannerImage() {
      let space = this.getRandomImages(this.proposal.Spaces, 1)[0];
      let image = this.getRandomImages(space.Images, 1)[0].Image;
      return image;
    },

    locationId() {
      return this.location.Id || 0;
    },

    /**
     * Location images
     */
    locationImages() {
      let arr_images = this.location.Images.filter(
        (i) => i.IsDefault === false
      );

      if (arr_images.length <= 3) {
        return arr_images;
      }

      return (arr_images = this.getRandomImages(arr_images, 3));
    },

    /**
     * location description
     */
    locationDescription() {
      let description = "";

      if (this.proposal && this.proposal.Location.Descriptions.length > 0) {
        let des = this.proposal.Location.Descriptions.find(
          (d) => d.Language === this.proposalLanguage
        );

        if (des) {
          description = des.Description;
        } else {
          des = this.proposal.Location.Descriptions.find(
            (d) => d.Language === "en"
          );

          if (des) {
            description = des.Description;
          }
        }
      }

      let a_tag = /<a (.*?)>/g;
      return description.replace(a_tag, '<a target="_blank" $1>');
    },

    /**
     * location features
     */
    locationFeatures() {
      let features = [];

      if (
        this.proposal &&
        this.proposal.Location &&
        this.proposal.Location.Features.length > 0
      ) {
        features = this.location.Features.filter(
          (f) => f.Language === this.proposalLanguage
        );

        if (features.length === 0) {
          features = this.proposal.Location.Features.filter(
            (f) => f.Language === "en"
          );
        }
      }

      return features;
    },
  },

  async created() {
    await this.getProposal();
  },

  provide() {
    let localeOverride = {};
    Object.defineProperty(localeOverride, "locale", {
      enumerable: true,
      get: () => this.proposalLanguage,
    });

    return {
      localeOverride,
    };
  },

  methods: {
    /**
     * Get proposal data
     */
    async getProposal() {
      let self = this;

      this.isLoading = true;

      await proposalProvider.methods
        .getProposal(self.proposalKey)
        .then((response) => {
          if (response.status === 200) {
            self.proposal = response.data;

            // Update view stats
            this.logProposalView();
          } else {
            this.$router.push({ name: "proposals" }).catch();
          }
        });

      if (self.proposal !== null) {
        await this.getLocationActiveManager(self.proposal.Location.Id);
      }

      this.isLoading = false;
    },

    logProposalView() {
      logProvider.methods.saveProposalView(this.proposal.RequestId);
    },

    /**
     * Get location active manager
     */
    async getLocationActiveManager() {
      try {
        const response = await locationProvider.methods.getActiveManager(
          this.proposal.Location.Id
        );
        if (response.status !== 200) {
          return;
        }
        this.host = response.data;
        if (this.host !== null) {
          this.animateHostTexts();
        }
      } catch (error) { }
    },

    /**
     * Build host texts
     */
    animateHostTexts() {
      // First build new host text array
      let arrTexts = [];
      switch (this.proposal.Status) {
        case "ProposalChangeRequest":
          arrTexts.push(
            this.$t("Views.Proposals.ProposalDetail.Text_HostChangeRequest_1", {
              br: "<br />",
              name: this.proposal.Booker,
            })
          );
          arrTexts.push(
            this.$t("Views.Proposals.ProposalDetail.Text_HostChangeRequest_2", {
              phone: this.proposal.Location.PhoneNumber,
            })
          );
          arrTexts.push(
            this.$t("Views.Proposals.ProposalDetail.Text_HostChangeRequest_3")
          );
          break;

        default:
          arrTexts.push(
            this.$t(
              "Views.Proposals.ProposalDetail.Text_HostWelcome_1",
              this.proposalLanguage,
              {
                br: "<br />",
                name: this.proposal.Booker,
              }
            )
          );
          arrTexts.push(
            this.$t(
              "Views.Proposals.ProposalDetail.Text_HostWelcome_2",
              this.proposalLanguage
            )
          );
          arrTexts.push(
            this.$t(
              "Views.Proposals.ProposalDetail.Text_HostWelcome_3",
              this.proposalLanguage,
              { phone: this.proposal.Location.PhoneNumber }
            )
          );
          arrTexts.push(
            this.$t(
              "Views.Proposals.ProposalDetail.Text_HostWelcome_4",
              this.proposalLanguage,
              {
                date: this.$options.filters.dateFormat(
                  this.proposal.ExpiresOn,
                  this.proposalLanguage,
                  "longDateFormat"
                ),
              }
            )
          );
          break;
      }

      // Reset and add new host texts
      this.hostTextAnimator && clearInterval(this.hostTextAnimator);
      this.hostTextDisplayIndex = 0;
      this.hostTexts = arrTexts;
      const nrOfTexts = arrTexts.length - 1;

      this.hostTextAnimator = setInterval(() => {
        this.hostTextDisplayIndex =
          nrOfTexts === this.hostTextDisplayIndex
            ? 0
            : this.hostTextDisplayIndex + 1;
      }, this.hostTextAnimationInterval);
    },

    /**
     * Get random images
     * @returns {Array} images
     */
    getRandomImages(arr, n) {
      let result = new Array(n);
      let len = arr.length;
      let taken = new Array(len);
      while (n--) {
        var x = Math.floor(Math.random() * len);
        result[n] = arr[x in taken ? taken[x] : x];
        taken[x] = --len in taken ? taken[len] : len;
      }
      return result;
    },

    /**
     * Process updated proposal
     */
    proposalUpdated(proposal) {
      this.proposal = proposal;
      this.pageScrollTo("proposalDetailTop");
      if (proposal.Status === "ProposalChangeRequest") {
        EventBus.$emit("showToast", {
          type: "info",
          message: this.$t(
            "Views.Proposals.ProposalDetail.Toast_ProposalChangeRequest",
            this.proposalLanguage
          ),
        });
      }
      if (this.host !== null) {
        this.animateHostTexts();
      }
    },

    /**
     * Proposal cancelled toast
     */
    proposalCancelled() {
      EventBus.$emit("showToast", {
        type: "info",
        message: this.$t(
          "Views.Proposals.ProposalDetail.Toast_ProposalCancelled",
          this.proposalLanguage
        ),
      });
      this.$router.push({ name: "proposals" }).catch();
    },

    /**
     * Accept proposal by user
     */
    async acceptProposal() {
      if (!self.isAcceptingProposal) {
        this.isAcceptingProposal = true;

        try {
          let res = await proposalProvider.methods.acceptProposal(
            this.proposalKey
          );
          if (res.status === 200) {
            this.reservation = res.data;

            this.$router.push({
              name: `reservation-detail`,
              params: { reservationKey: this.reservation.Key },
            });
          }
        } catch (error) {
          this.notTheApplicant = true;
          this.isAcceptingProposal = false;
        } finally {
        }
      }
    },

    /**
     * Get space name
     */
    getSpaceName(space) {
      if (!space.Texts.length) {
        return "";
      }

      let text =
        space.Texts.find((t) => t.LanguageId === this.proposal.LanguageId) ||
        space.Texts.find((t) => t.Language === "en");
      return text.Name;
    },

    /**
     * Get space description
     */
    getSpaceDescription(space) {
      if (!space.Texts.length) {
        return [""];
      }

      let text =
        space.Texts.find((t) => t.LanguageId === this.proposal.LanguageId) ||
        space.Texts.find((t) => t.Language === "en");
      return text.Description;
    },

    /**
     * Get space usp's
     */
    getSpaceUsps(space) {
      if (!space.Features.length) {
        return [];
      }

      let output =
        space.Features.filter(
          (t) => t.LanguageId === this.proposal.LanguageId
        ) || space.Features.filter((t) => t.Language === "en");

      return Array.from(output, (f) => f.Body);
    },

    /**
     * Show change request modal
     */
    setShowChangeRequest() {
      this.showChangeRequest = true;
    },

    /**
     * Show reject modal
     */
    setShowReject() {
      this.showReject = true;
    },

    /**
     * Hide modal
     */
    hideModal() {
      this.showChangeRequest = false;
      this.showReject = false;
    },

    /**
     * Scroll controller
     */
    pageScrollTo(refName) {
      EventBus.$emit(
        "pageScrollTo",
        this.$refs[refName].getBoundingClientRect().top
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.proposalDetail {
  .section {
    padding-left: $gap;
    padding-right: $gap;
  }

  .hero {
    position: relative;
    aspect-ratio: 16/9;

    .box-heroTitle {
      .boxTitle {
        float: left;
        background: hsla(0, 0%, 100%, 0.75);
        max-width: 50%;
        border-radius: $gap $gap $gap 0;
      }

      .boxDetail {
        float: left;
        clear: left;
        border-radius: 0 0 $gap $gap;
        background: hsla(0, 0%, 100%, 0.75);
        position: relative;

        &::before {
          content: " ";
          position: absolute;

          background-color: transparent;
          top: 0px;
          right: -15px;
          height: 15px;
          width: 15px;
          overflow: hidden;
          background-image: url("/public/img/invertCorner.svg");
          opacity: 0.75;
        }
      }
    }
  }

  .box-address {
    position: absolute;
    padding: $gap $gap (3rem + 1rem);
    bottom: -3rem;
    right: 0;
    border-radius: $gap 0 0 0;
    width: 33.3333%;
    background: hsla(0, 0%, 100%, 0.85);
  }

  .column-images {
    border-radius: 0 0 0 $gap;
  }

  ::v-deep .optionDetailItem img,
  .is-rounded {
    border-radius: $gap;
  }

  .section-spaces,
  .section-amenities {
    .section-divider {
      margin-left: -$gap;
      margin-right: -$gap;
    }
  }


  .section-summary {
    position: relative;
    padding-top: 0;
    .section-divider {
      margin-left: -$gap;
      margin-right: -$gap;
    }
  }

  @media screen and (max-width: 1019px) {
    .box-address {
      padding: $gap;
      bottom: 0;
    }
  }
}

.message-host {
  display: flex;
  min-height: 130px;
  margin-top: -$gap * 2;
  margin-left: -$gap;
  margin-right: -$gap;
  align-items: center;

  .image {
    flex-grow: 0;
    flex-shrink: 0;
    margin-right: $gap;
    div {
      height: 100%;
      border-radius: 50%;
    }
  }

  .text {
    display: flex;
    align-self: center;
    flex-grow: 1;
    flex-shrink: 1;
  }
}

.section-spaces {
  .box-settings {
    padding-bottom: $gap;
  }
  .box-people {
    padding-bottom: $gap;
    .icon {
      height: 48px;
    }
  }
}

.spaceBannerBgImage {
  display: flex;
  align-items: center;
  justify-content: center;
  background-attachment: fixed;
  height: 240px;
  background: $blue-dark !important;
  .title {
    $pd: 10px;
    padding: $pd ($pd * 3) ($pd * 2);
    background: hsla(0, 0%, 100%, 0.6);
  }
}

.section-divider {
  box-shadow: inset 0px 5px 13px -12px #000000;
  position: relative;
  background-color: transparent;
  overflow: hidden;
  padding-top: 35px;
  &::before {
    position: absolute;
    content: " ";
    top: -20px;
    left: 50%;
    margin-left: -20px;
    width: 40px;
    height: 40px;
    background: white;
    transform: rotate(45deg);
    box-shadow: 0px 0px 6px -3px #000000;
  }

}
</style>
