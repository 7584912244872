var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-proposalDetail"},[(_vm.showChangeRequest)?_c('ModalChangeRequestProposal',{attrs:{"showModal":_vm.showChangeRequest,"onClickCancel":_vm.hideModal,"proposal":_vm.proposal},on:{"proposalUpdated":_vm.proposalUpdated}}):_vm._e(),(_vm.showReject)?_c('ModalCancelProposal',{attrs:{"showModal":_vm.showReject,"onClickCancel":_vm.hideModal,"proposal":_vm.proposal},on:{"proposalCancelled":_vm.proposalCancelled}}):_vm._e(),_c('nav',{staticClass:"navbar-custom"},[_c('div',{staticClass:"navbar-item"},[_c('router-link',{staticClass:"navbar-link icon-text is-arrowless",attrs:{"to":{ name: 'proposals' }}},[_c('span',{staticClass:"icon is-small"},[_c('i',{staticClass:"fas fa-chevron-left"})]),_c('span',[_vm._v("Proposals")])])],1)]),_c('hr'),_c('div',{staticClass:"contentWrapper has-background-white"},[_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(_vm.isLoading)?_c('ui-loader'):_vm._e(),(!_vm.isLoading && _vm.proposal)?_c('div',{ref:"proposalDetailTop",staticClass:"proposalDetail"},[_c('div',{staticClass:"has-padding is-hidden-desktop"},[_c('div',{directives:[{name:"t",rawName:"v-t",value:({
              path: 'Views.Proposals.ProposalDetail.Title_Proposal',
              locale: _vm.proposalLanguage,
            }),expression:"{\n              path: 'Views.Proposals.ProposalDetail.Title_Proposal',\n              locale: proposalLanguage,\n            }"}],staticClass:"subtitle is-size-3-touch is-size-2-desktop"}),_c('div',{staticClass:"title is-size-3-mobile is-size-2-tablet is-size-1-desktop",domProps:{"innerHTML":_vm._s(_vm.proposal.Location.Name)}}),_c('div',{staticClass:"is-size-5-touch is-size-3-desktop"},[_vm._v(" "+_vm._s(_vm._f("dateFormat")(_vm.proposal.StartDate,_vm.proposalLanguage, "longDateFormat"))+", "+_vm._s(_vm._f("minutesToTime")(_vm.proposal.StartMinutes))+" - "+_vm._s(_vm._f("minutesToTime")(_vm.proposal.EndMinutes))+" ")]),_c('strong',[_vm._v("Proposal is valid until "+_vm._s(_vm._f("dateFormat")(_vm.proposal.ExpiresOn,_vm.proposalLanguage, "longDateFormat")))])]),_c('ImgLoader',{staticClass:"hero",attrs:{"src":_vm.location.Image,"type":"location","typeId":_vm.location.Id,"tag":"div"}},[_c('div',{staticClass:"hero-body"},[_c('div',{staticClass:"container has-text-left"},[_c('div',{staticClass:"box-heroTitle is-hidden-touch"},[_c('div',{staticClass:"boxTitle p-4"},[_c('div',{directives:[{name:"t",rawName:"v-t",value:({
                      path: 'Views.Proposals.ProposalDetail.Title_Proposal',
                      locale: _vm.proposalLanguage,
                    }),expression:"{\n                      path: 'Views.Proposals.ProposalDetail.Title_Proposal',\n                      locale: proposalLanguage,\n                    }"}],staticClass:"subtitle is-size-3-touch is-size-2-desktop"}),_c('div',{staticClass:"title is-size-3-mobile is-size-2-tablet",class:_vm.proposal.Location.Name.length > 30
                        ? 'is-size-2-desktop'
                        : 'is-size-1-desktop',domProps:{"innerHTML":_vm._s(_vm.proposal.Location.Name)}})]),_c('div',{staticClass:"boxDetail pl-4 pr-4 pb-4"},[_c('div',{staticClass:"mb-2 is-size-5-touch is-size-3-desktop"},[_vm._v(" "+_vm._s(_vm._f("dateFormat")(_vm.proposal.StartDate,_vm.proposalLanguage, "longDateFormat"))+" ")]),_c('div',{staticClass:"mb-5 is-size-5-touch is-size-3-desktop"},[_vm._v(" "+_vm._s(_vm._f("minutesToTime")(_vm.proposal.StartMinutes))+" - "+_vm._s(_vm._f("minutesToTime")(_vm.proposal.EndMinutes))+" ")]),_c('div',{staticClass:"buttons"},[(!_vm.isInRequestState)?_c('a',{directives:[{name:"t",rawName:"v-t",value:({
                        path: 'Views.Proposals.ProposalDetail.Button_ViewSummary',
                        locale: _vm.proposalLanguage,
                      }),expression:"{\n                        path: 'Views.Proposals.ProposalDetail.Button_ViewSummary',\n                        locale: proposalLanguage,\n                      }"}],staticClass:"button is-info is-medium is-fullwidth",on:{"click":function($event){return _vm.pageScrollTo('summary')}}}):_vm._e()])])])])]),_c('div',{staticClass:"box-address"},[_c('div',{directives:[{name:"t",rawName:"v-t",value:({
                path: 'Views.Proposals.ProposalDetail.Title_Address',
                locale: _vm.proposalLanguage,
              }),expression:"{\n                path: 'Views.Proposals.ProposalDetail.Title_Address',\n                locale: proposalLanguage,\n              }"}],staticClass:"has-text-weight-bold is-size-4"}),_c('div',[_vm._v(_vm._s(_vm.location.Address))]),_c('div',[_vm._v(_vm._s(_vm.location.Zipcode)+", "+_vm._s(_vm.location.City))]),_c('div',[_vm._v(_vm._s(_vm.location.PhoneNumber))]),_c('div',[_vm._v(_vm._s(_vm.location.Email))])])]),_c('div',{staticClass:"section"},[_c('div',{staticClass:"columns is-multiline"},[(_vm.locationDescription)?_c('div',{staticClass:"column"},[_c('div',{staticClass:"message-host has-padding has-margin-bottom-x2 is-size-4"},[_c('figure',{staticClass:"image is-64x64"},[(_vm.host && _vm.host.Image)?_c('ImgLoader',{staticClass:"has-background-grey is-rounded",attrs:{"src":_vm.host.Image,"type":"location","typeId":_vm.host.LocationId,"tag":"div"}}):_vm._e()],1),_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[_c('div',{key:`ht_${_vm.hostTextDisplayIndex}`,staticClass:"text",domProps:{"innerHTML":_vm._s(_vm.hostTexts[_vm.hostTextDisplayIndex])}})])],1),_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(!_vm.isInRequestState)?_c('div',{key:"notInRequestState",domProps:{"innerHTML":_vm._s(_vm.locationDescription)}}):_vm._e(),(_vm.isInRequestState)?_c('message',{key:"inRequestState",staticClass:"is-info"},[_c('div',{staticClass:"is-size-4"},[_vm._v("Pending...")])]):_vm._e()],1)],1):_vm._e(),_c('div',{staticClass:"column-images column is-full-tablet is-one-third-desktop"},[_c('div',{staticClass:"columns is-multiline is-mobile"},_vm._l((_vm.locationImages),function(img,index){return _c('div',{key:'i_' + index,staticClass:"column is-full-mobile is-half-tablet is-full-desktop",class:[
                    { 'is-hidden-touch': index === 2 },
                    { 'is-hidden': _vm.isInRequestState && index >= 1 },
                  ]},[_c('figure',{staticClass:"image is-4by3"},[_c('ImgLoader',{staticClass:"has-background-grey-light is-rounded",attrs:{"src":img.Image,"type":"location","typeId":img.LocationId}})],1)])}),0)])])]),(!_vm.isInRequestState)?_c('div',[_c('div',{staticClass:"section-spaces section pt-0"},[_c('div',{staticClass:"section-divider mb-5"},[_c('div',{directives:[{name:"t",rawName:"v-t",value:({
                  path: 'Views.Proposals.ProposalDetail.Title_Spaces',
                  locale: _vm.proposalLanguage,
                }),expression:"{\n                  path: 'Views.Proposals.ProposalDetail.Title_Spaces',\n                  locale: proposalLanguage,\n                }"}],staticClass:"title is-1 has-text-centered"})]),_c('div',{staticClass:"columns is-multiline"},[_c('div',{staticClass:"column is-full"},_vm._l((_vm.proposal.Spaces),function(space,index){return _c('div',{key:'sp' + index,staticClass:"columns-space columns is-multiline"},[_c('div',{staticClass:"column-spaceText column is-half-tablet is-one-third-desktop"},[_c('div',{staticClass:"title is-2",domProps:{"innerHTML":_vm._s(_vm.getSpaceName(space))}}),_c('UpsellTemplate',{staticClass:"mb-5",attrs:{"upsellItems":_vm.getSpaceUsps(space)}}),_c('div',{staticClass:"mb-5",domProps:{"innerHTML":_vm._s(_vm.getSpaceDescription(space))}}),_c('div',{staticClass:"columns is-mobile"},[_c('div',{staticClass:"column has-text-centered"},[_c('div',{staticClass:"box-settings"},[_c('SvgSetting',{attrs:{"aria-hidden":"true","settingId":space.SettingId,"size":"is-large has-text-blue-dark"}}),_c('div',{staticClass:"subtitle"},[_vm._v(" "+_vm._s(_vm._f("getSettingName")(space.SettingId))+" ")])],1)]),_c('div',{staticClass:"column has-text-centered"},[_c('div',{staticClass:"box-people box-icon"},[_c('span',{staticClass:"icon is-large has-text-blue-dark"},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'users'],"size":"2x"}})],1),_c('div',{staticClass:"subtitle"},[_vm._v(_vm._s(space.Seats))])])])])],1),_c('div',{staticClass:"column-spaceImage column"},[_c('figure',{staticClass:"image-space image is-16by9"},[_c('ImgLoader',{staticClass:"has-background-grey-light has-ratio is-rounded",attrs:{"src":space.Image,"type":"location","typeId":_vm.locationId,"tag":"div"}})],1)])])}),0)])]),(_vm.proposal.Options)?_c('section',{staticClass:"section-amenities section pt-0"},[_c('div',{staticClass:"section-divider mb-5"},[_c('div',{directives:[{name:"t",rawName:"v-t",value:({
                  path: 'Views.Proposals.ProposalDetail.Title_Amenities',
                  locale: _vm.proposalLanguage,
                }),expression:"{\n                  path: 'Views.Proposals.ProposalDetail.Title_Amenities',\n                  locale: proposalLanguage,\n                }"}],staticClass:"title is-1 has-text-centered"})]),_c('div',{staticClass:"container"},[_c('div',{staticClass:"columns is-multiline"},_vm._l((_vm.proposal.Options),function(option,index){return _c('div',{key:index,class:_vm.optionColumns},[_c('OptionDetailItem',{staticClass:"optionDetailItem",attrs:{"locationId":_vm.location.Id,"language":_vm.proposalLanguage,"option":option}})],1)}),0)])]):_vm._e(),_c('section',{ref:"summary",staticClass:"section-summary section"},[_c('div',{staticClass:"section-divider mb-5"},[_c('div',{directives:[{name:"t",rawName:"v-t",value:({ path: 'Views.Proposals.ProposalDetail.Title_Summary', locale: _vm.proposalLanguage }),expression:"{ path: 'Views.Proposals.ProposalDetail.Title_Summary', locale: proposalLanguage }"}],staticClass:"title is-1 has-text-centered"})]),_c('div',{staticClass:"container"},[_c('div',{staticClass:"is-size-3 has-text-weight-bold",domProps:{"innerHTML":_vm._s(_vm.proposal.Location.Name)}}),_c('div',{staticClass:"is-size-5"},[_vm._v(_vm._s(_vm._f("dateFormat")(_vm.proposal.StartDate,_vm.proposalLanguage, 'longDateFormat')))]),_c('div',{staticClass:"is-size-5"},[_vm._v(_vm._s(_vm._f("minutesToTime")(_vm.proposal.StartMinutes))+" - "+_vm._s(_vm._f("minutesToTime")(_vm.proposal.EndMinutes)))]),_c('div',{directives:[{name:"t",rawName:"v-t",value:({ path: 'Views.Proposals.ProposalDetail.Text_ProposalIsValid', locale: _vm.proposalLanguage, args: {
                  date: _vm.$options.filters.dateFormat(_vm.proposal.ExpiresOn, _vm.proposalLanguage, 'longDateFormat')
                }}),expression:"{ path: 'Views.Proposals.ProposalDetail.Text_ProposalIsValid', locale: proposalLanguage, args: {\n                  date: $options.filters.dateFormat(proposal.ExpiresOn, proposalLanguage, 'longDateFormat')\n                }}"}],staticClass:"is-size-5 mb-5"}),_c('ProposalInvoiceView',{attrs:{"proposal":_vm.proposal,"language":_vm.proposalLanguage}})],1)]),_c('div',{ref:"approveActions"},[_c('div',{staticClass:"section-divider section-divider-approve"}),_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(_vm.isLoggedIn && _vm.notTheApplicant)?_c('div',{key:"userNotTheApplicant",staticClass:"has-padding"},[_c('div',{staticClass:"columns is-centered"},[_c('div',{staticClass:"column is-half-desktop"},[_c('Message',{staticClass:"is-warning"},[_c('div',{domProps:{"innerHTML":_vm._s(
                          _vm.$t(
                            'Views.Proposals.ProposalDetail.Message_NotTheApplicant',
                            _vm.proposalLanguage,
                            { name: _vm.profile.FirstName }
                          )
                        )}})])],1)])]):_vm._e(),(_vm.isLoggedIn)?_c('div',{key:"userIsLoggedIn"},[_c('section',{staticClass:"section section-approve pt-0"},[_c('div',{staticClass:"buttons is-centered"},[_c('a',{staticClass:"button is-large mt-4 is-primary",class:[
                        { 'is-disabled': _vm.isAcceptingProposal },
                        { 'is-loading': _vm.isAcceptingProposal },
                      ],attrs:{"disabled":_vm.isAcceptingProposal},on:{"click":_vm.acceptProposal}},[_c('span',{directives:[{name:"t",rawName:"v-t",value:({
                          path: 'Views.Proposals.ProposalDetail.Button_Accept',
                          locale: _vm.proposalLanguage,
                        }),expression:"{\n                          path: 'Views.Proposals.ProposalDetail.Button_Accept',\n                          locale: proposalLanguage,\n                        }"}],staticClass:"pl-4 pr-4"})])])]),(!_vm.isAcceptingProposal)?_c('section',{staticClass:"pb-6"},[_c('div',{directives:[{name:"t",rawName:"v-t",value:({
                      path: 'Views.Proposals.ProposalDetail.Label_Or',
                      locale: _vm.proposalLanguage,
                    }),expression:"{\n                      path: 'Views.Proposals.ProposalDetail.Label_Or',\n                      locale: proposalLanguage,\n                    }"}],staticClass:"title is-3 has-text-centered"}),_c('div',{staticClass:"buttons is-centered"},[_c('a',{staticClass:"button is-ghost mr-3",on:{"click":_vm.setShowChangeRequest}},[_c('span',{staticClass:"icon has-text-info"},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'comment-lines']}})],1),_c('span',{directives:[{name:"t",rawName:"v-t",value:({
                          path: 'Views.Proposals.ProposalDetail.Link_RequestChanges',
                          locale: _vm.proposalLanguage,
                        }),expression:"{\n                          path: 'Views.Proposals.ProposalDetail.Link_RequestChanges',\n                          locale: proposalLanguage,\n                        }"}]},[_vm._v("Request changes")])]),_c('a',{staticClass:"button is-ghost ml-3",on:{"click":_vm.setShowReject}},[_c('span',{staticClass:"icon has-text-danger"},[_c('font-awesome-icon',{attrs:{"icon":['far', 'times']}})],1),_c('span',{directives:[{name:"t",rawName:"v-t",value:({
                          path: 'Views.Proposals.ProposalDetail.Link_RejectProposal',
                          locale: _vm.proposalLanguage,
                        }),expression:"{\n                          path: 'Views.Proposals.ProposalDetail.Link_RejectProposal',\n                          locale: proposalLanguage,\n                        }"}]})])])]):_vm._e()]):_vm._e(),(!_vm.isLoggedIn)?_c('div',{key:"userNotLoggedIn",staticClass:"has-padding"},[_c('div',{staticClass:"columns is-centered"},[_c('div',{staticClass:"column is-half-desktop"},[_c('div',{staticClass:"has-text-weight-bold is-size-5",domProps:{"textContent":_vm._s(
                        _vm.$t(
                          'Views.Proposals.ProposalDetail.Message_Header_NotLoggedIn',
                          _vm.proposalLanguage
                        )
                      )}}),_c('div',{staticClass:"mb-5",domProps:{"innerHTML":_vm._s(
                        _vm.$t(
                          'Views.Proposals.ProposalDetail.Message_Text_NotLoggedIn',
                          _vm.proposalLanguage
                        )
                      )}}),_c('div',{staticClass:"box"},[_c('no-auth-login-flow',{attrs:{"inputSize":"","logginOnly":true}})],1)])])]):_vm._e()])],1)]):_vm._e()],1):_vm._e(),(!_vm.isLoading && !_vm.proposal)?_c('message',{staticClass:"is-info"},[_c('div',{domProps:{"innerHTML":_vm._s(
            _vm.$t(
              'Views.Proposals.ProposalDetail.Message_NotExists',
              _vm.proposalLanguage
            )
          )}})]):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }