<template>
  <ui-component-modal
    :modalTitle="$t('Components.Proposals.ModalCancelProposal.Maint_Title', localeOverride.locale)"
    :buttonLabelConfirm="$t('Components.Proposals.ModalCancelProposal.Button_Reject', localeOverride.locale)"
    confirmButtonCssClass="is-danger"
    :isSaving="isSaving"
    :isSavingSuccess="isSavingSuccess"
    :isSavingError="isSavingError"
    :disableSaveButton="disableSaveButton"
    :hideFooterButtons="isSavingError || isSavingSuccess"
    :onClickCancel="onClickCancel"
    :onClickSave="cancelProposal"
    :showModal="showModal"
    @closeModal="onClickCancel"
  >
    <template slot="modalTitle"></template>
    <template v-slot:content>
      <div>
        <message
          v-if="!notTheApplicant"
          class="is-info"
        >
          <div v-html="$t('Components.Proposals.ModalCancelProposal.Message_Info', localeOverride.locale, {phone: proposal.Location.PhoneNumber})"></div>
        </message>
        <message
          v-if="notTheApplicant"
          class="is-warning"
        >
          <div v-html="$t('Components.Proposals.ModalCancelProposal.Message_NotTheApplicant', localeOverride.locale, {name: profile.FirstName})"></div>
        </message>
        <table class="table is-fullwidth">
          <tbody>
            <tr>
              <td class="has-text-vertical-middle">
                {{
                  $t(
                    'Components.Proposals.ModalCancelProposal.Label_CancelReason', localeOverride.locale
                  )
                }}
              </td>
              <td>
                <div class="select">
                  <select v-model="cancelReason">
                    <option
                      v-for="predefinedReason in predefinedReasons"
                      :key="predefinedReason.Id"
                      :value="predefinedReason.Id"
                    >{{ predefinedReason.Name }}</option>
                  </select>
                </div>
              </td>
            </tr>
            <tr v-if="cancelReason === 6">
              <td>
                {{
                  $t(
                    'Components.Proposals.ModalCancelProposal.Label_OtherReason', localeOverride.locale
                  )
                }}
              </td>
              <td>
                <textarea
                  class="textarea has-fixed-size"
                  v-model="reason"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </template>
  </ui-component-modal>
</template>

<script>
import { mapState } from 'vuex'
import proposalProvider from '../../providers/proposal'
import Message from '../UI/Message.vue'

export default {
  components: { Message },

  inject: {
    localeOverride: {
      type: Object,
      default: function() { return { locale: this.$i18n.locale } }
    },
  },

  props: {
    showModal: {
      type: Boolean,
      default: false,
    },

    onClickCancel: {
      type: Function,
      required: true,
    },

    proposal: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      cancelReason: 0,
      isSaving: false,
      isSavingSuccess: false,
      isSavingError: false,
      reason: '',
      predefinedReasons: [],
      notTheApplicant: false,
    }
  },

  computed: {
    ...mapState(['profile']),
  },

  created() {
    this.predefinedReasons.push({
      Id: 0,
      Name: this.$t(
        'Components.Proposals.ModalCancelProposal.Reason_NoReasonSpecified', this.localeOverride.locale
      ),
    })
    this.predefinedReasons.push({
      Id: 1,
      Name: this.$t(
        'Components.Proposals.ModalCancelProposal.Reason_NoParticipants', this.localeOverride.locale
      ),
    })
    this.predefinedReasons.push({
      Id: 2,
      Name: this.$t(
        'Components.Proposals.ModalCancelProposal.Reason_FoundCheaperLocation', this.localeOverride.locale
      ),
    })
    this.predefinedReasons.push({
      Id: 3,
      Name: this.$t(
        'Components.Proposals.ModalCancelProposal.Reason_OtherRegion', this.localeOverride.locale
      ),
    })
    this.predefinedReasons.push({
      Id: 4,
      Name: this.$t(
        'Components.Proposals.ModalCancelProposal.Reason_MeetingIsCancelled', this.localeOverride.locale
      ),
    })
    this.predefinedReasons.push({
      Id: 5,
      Name: this.$t(
        'Components.Proposals.ModalCancelProposal.Reason_MeetingIsRescheduled', this.localeOverride.locale
      ),
    })
    this.predefinedReasons.push({
      Id: 6,
      Name: this.$t(
        'Components.Proposals.ModalCancelProposal.Reason_Other', this.localeOverride.locale
      ),
    })
  },

  methods: {
    cancelProposal() {
      let self = this

      if (!self.isSaving) {
        self.isSaving = true
        this.disableSaveButton = true

        proposalProvider.methods
          .cancelProposal(self.proposal.Key, self.cancelReason, self.reason)
          .then((response) => {
            if (response.status === 204) {
              this.$emit('proposalCancelled', response.data)

              this.onClickCancel()
            }
          })
          .catch((e) => {
            this.notTheApplicant = true
            this.disableSaveButton = true
          })
          .finally(() => {
            self.isSaving = false
          })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.table {
  tr {
    td:first-child {
      width: 280px;
    }
  }
}
</style>