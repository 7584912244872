<template>
  <div v-if="proposal">
    <table class="table is-fullwidth">
      <thead>
        <tr>
          <th v-t="{ path: 'Views.Proposals.ProposalDetail.Title_Spaces', locale: proposal.Language }"></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(space, index) in proposal.Spaces"
          :key="index"
        >
          <td width="50">{{ space.Seats }}x</td>
          <td>{{ getSpaceName(space) }}</td>
          <td>
            {{ space.StartMinutes | minutesToTime }} -
            {{ space.EndMinutes | minutesToTime }}
          </td>
          <td></td>
          <td
            width="100"
            class="has-text-right"
          >
            {{ space.PriceTotal | toCurrency }}
          </td>
        </tr>
      </tbody>
    </table>

    <table class="table is-fullwidth">
      <thead>
        <tr>
          <th v-t="{ path: 'Views.Proposals.ProposalDetail.Title_Amenities', locale: proposal.Language }"></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(catOption, index) in proposalOptions"
          :key="index"
        >
          <template v-if="catOption.isHeader">
            <td
              colspan="5"
              class="has-text-weight-bold"
              v-html="catOption.header"
            ></td>
          </template>
          <template v-if="!catOption.isHeader">
            <td width="50">{{ catOption.Quantity }}x</td>
            <td>{{ getOptionName(catOption) }}</td>
            <td></td>
            <td></td>
            <td
              width="100"
              class="has-text-right"
            >
              {{ catOption.PriceTotal | toCurrency }}
            </td>
          </template>
        </tr>
      </tbody>
    </table>

    <table class="table is-fullwidth">
      <tbody>
        <tr class="has-text-weight-bold">
          <td v-t="{ path: 'Views.Proposals.ProposalDetail.Text_TotalExclVAT', locale: proposal.Language }"></td>
          <td class="has-text-right">
            {{ proposal.TotalExclTax | toCurrency }}
          </td>
        </tr>
        <tr
          v-for="(total, index) in proposal.TaxTotals"
          :key="index"
        >
          <td v-t="{ path: 'Views.Proposals.ProposalDetail.Label_VAT', locale: proposal.Language, args: {
            persentage: total.Percentage
          } }"></td>
          <td
            width="100"
            class="has-text-right"
          >
            {{ total.Total | toCurrency }}
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr class="has-text-weight-bold">
          <td v-t="{ path: 'Views.Proposals.ProposalDetail.Text_TotalInclVAT', locale: proposal.Language }"></td>
          <td class="has-text-right">
            {{ proposal.TotalInclTax | toCurrency }}
          </td>
        </tr>
      </tfoot>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    proposal: {
      type: Object,
      default: null,
    },
  },

  computed: {
    proposalOptions() {
      let categories = []
      let optionsArr = []

      if (this.proposal.Options.length > 0) {
        for (let i = 0; i < this.proposal.Options.length; i++) {
          let option = this.proposal.Options[i]

          if (categories.findIndex((c) => c === option.CategoryId) === -1) {
            categories.push(option.CategoryId)
          }
        }
      }

      categories = categories.sort()

      categories.forEach(categoryId => {
        optionsArr.push({
          isHeader: true,
          header: this.$t('Components.Reservation.ReservationOptions.Category_' + categoryId, this.proposal.Language),
          option: {}
        })
        const catOptArr = this.proposal.Options.filter((o) => {
          if (o.CategoryId === categoryId) {
            return {
              isHeader: false,
              header: '',
              option: o
            }
          }
        })
        optionsArr.push(...catOptArr)
      })

      return optionsArr
    },
  },

  methods: {
    getSpaceName(space) {
      let name = ''

      if (space && space.Texts && space.Texts.length > 0) {
        let text = space.Texts.find((t) => t.Language === (typeof this.proposal.Language !== 'undefined' ? this.proposal.Language : 'en'))

        if (text) {
          name = text.Name
        } else {
          text = space.Texts.find((d) => d.Language === 'en')

          if (text) {
            name = text.Name
          }
        }
      }

      return name
    },

    getOptionName(option) {
      let name = ''

      if (option && option.Texts && option.Texts.length > 0) {
        let text = option.Texts.find((t) => t.Language === (typeof this.proposal.Language !== 'undefined' ? this.proposal.Language : 'en'))

        if (text) {
          name = text.Name
        } else {
          text = option.Texts.find((d) => d.Language === 'en')

          if (text) {
            name = text.Name
          }
        }
      }

      return name
    },
  },
}
</script>
