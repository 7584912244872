<script>
export default {
  name: 'ImgLoader',

  props: {
    tag: {
      type: String,
      default: 'img'
    },
    src: {
      type: String,
      default: '',
      required: true
    },
    sizes: {
      type: Array,
      default: function() { return [] }
    },
    type: {
      type: String,
      default: '',
      note: 'Eq. profile, location, event. Leave this blank if you want to load a normal image'
    },
    typeId: {
      type: Number,
      default: 0
    }
  },

  data() {
    return {
      parentNode: null,
      imageSrc: '',
      mSizes: this.sizes

    }
  },

  computed: {
    isExternalImage() {
      return !this.type && !this.typeId
    },

    imageSource() {
      if (this.imageSrc.indexOf('@/assets') !== -1) {
        return this.imageSrc.replace('@/assets', '')
      }
      return this.imageSrc
    }
  },

  created() {
    if (this.isExternalImage) {
      this.imageSrc = this.src
      return
    }
    this.getTypeSizes()
  },

  mounted() {
    this.parentNode = this.$el.parentNode

    if (!this.isExternalImage) {
      window.addEventListener('resize', this.getSizedImage)
    }
    this.getSizedImage()
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.getSizedImage)
  },

  methods: {
    getSizedImage() {
      const pnSize = this.parentNode.getBoundingClientRect()

      if (this.isExternalImage) {
        this.imageSrc = this.src
        return
      }

      const size = Object.keys(this.mSizes).find(key => {
        return pnSize.width <= Number(key)
      }) || 0

      if (this.type === 'profile') {
        this.imageSrc = this.$options.filters.getgetProfileImage(this.src, this.typeId, size)
      }
      if (this.type === 'location') {
        this.imageSrc = this.$options.filters.getLocationImage(this.src, this.typeId, size)
      }
      if (this.type === 'event') {
        this.imageSrc = this.$options.filters.getEventImageSrc(this.src, this.typeId, size)
      }

      this.imageSrc = this.imageSrc
    },

    getTypeSizes() {
      if (!this.type) { return }

      let sizes = {
        160: '160x120_',
        320: '320x240_',
        640: '640x480_'
      }

      switch (this.type) {
        case 'host':
          break

        case 'logo':
          break

        case 'profile':
          sizes = {
            38: '_38x38',
            84: '_84x84',
            120: '_120x120',
            150: '_150x150',
            300: '_300x300'
          }
          break
      }

      this.mSizes = sizes
    },
  },

  render(h) {
    if (this.tag === 'img') {
      return h(this.tag, {
        class: ['has-background-white-ter'],
        attrs: {
          src: this.imageSource,
        }
      })
    }

    return h(this.tag, {
      class: ['has-background-white-ter'],
      style: {
        backgroundImage: `url('${this.imageSource}')`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center'
      }
    },
      this.$slots.default)
  }
}
</script>