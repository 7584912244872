<template>
  <div>
    <proposal-comment-item
      v-for="comment in filterComments"
      :key="`pc_${comment.Id}`"
      :commentObj="comment"
      :comments="proposal.Comments"
    ></proposal-comment-item>
  </div>
</template>

<script>
import ProposalCommentItem from './ProposalCommentItem.vue'
export default {
  components: { ProposalCommentItem },
  props: {
    proposal: {
      type: Object,
      required: true
    }
  },

  computed: {
    filterComments() {
      return this.proposal.Comments.filter((c) => c.ParentId === 0 && !c.IsDeleted)
    },
  },
}
</script>

<style lang="scss" scoped>
</style>